// @ts-nocheck

import cn from 'classnames';
import React from 'react';
import Slider from 'react-slick';

import styles from './Carousel.module.scss';
import { ICarousel } from './CarouselTypes';

export const Carousel: React.FC<ICarousel> = props => {
  const {
    children,
    areDotsVisible = false,
    isInfinite = false,
    settings,
    className,
    onlyActiveSlidesVisible = true,
  } = props;

  const builtinSettings = {
    dots: areDotsVisible,
    infinite: isInfinite,
    speed: 300,
  };

  const mergedOSettings = { ...builtinSettings, ...settings };

  return (
    <div className={cn(className, { [styles.carouselVisiblity]: onlyActiveSlidesVisible })}>
      <Slider {...mergedOSettings}>{children}</Slider>
    </div>
  );
};
