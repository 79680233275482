import { useTranslation } from 'react-i18next';

import { PAGES_ENUM } from '../../../utils/constants';
import styles from './MainFooter.module.scss';
import { MainFooterCard } from './MainFooterCard';
import { MainFooterAbout } from './MainFooterCard/MainFooterAbout';
import { MainFooterLinksList } from './MainFooterCard/MainFooterLinksList';
import { MainFooterNewsletter } from './MainFooterCard/MainFooterNewsletter';

const mainFooterForParents = [
  {
    id: 1,
    link: `${PAGES_ENUM.ONLINE_GAMES}`,
    title: 'forParents.articles',
  },
];

const mainFooterForChildren = [
  {
    id: 1,
    link: `${PAGES_ENUM.COURSES}`,
    title: 'forChildren.videoCourses',
  },
  {
    id: 2,
    link: `${PAGES_ENUM.PRODUCTS}`,
    title: 'forChildren.books',
  },
  {
    id: 3,
    link: 'https://kidcanvas.skrinkaznan.com/',
    title: 'forChildren.painting',
    external: true,
  },
  {
    id: 4,
    link: `${PAGES_ENUM.ONLINE_LESSONS}`,
    title: 'forParents.webinars',
  },
];

const mainFooterInformation = [
  {
    id: 1,
    link: '/',
    title: 'information.home',
  },
  {
    id: 2,
    link: `${PAGES_ENUM.ABOUT}`,
    title: 'information.aboutUs',
  },
  {
    id: 3,
    link: `${PAGES_ENUM.PRIVACY_POLICY}`,
    title: 'information.privacyPolicy',
  },
  {
    id: 4,
    link: `${PAGES_ENUM.TERMS_OF_SERVICE}`,
    title: 'information.termsOfService',
  },
  {
    id: 5,
    link: `${PAGES_ENUM.COPYRIGHT}`,
    title: 'information.copyright',
  },
  {
    id: 6,
    link: `${PAGES_ENUM.FAQ}`,
    title: 'information.faq',
  },
  {
    id: 7,
    link: `${PAGES_ENUM.CONTACT_US}`,
    title: 'information.contacts',
  },
];

export const MainFooter = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.mainFooter}>
      <div className="row g-0">
        <MainFooterCard title={t('footer.platformTitle')} className="col-xl-3 col-lg-3 col-sm-6">
          <MainFooterAbout />
        </MainFooterCard>
        <MainFooterCard
          title={t('footer.information.title')}
          className="col-xl-2 col-lg-2 col-sm-6"
        >
          <MainFooterLinksList list={mainFooterInformation} />
        </MainFooterCard>
        <MainFooterCard title={t('footer.forParents.title')} className="col-xl-2 col-lg-2 col-sm-6">
          <MainFooterLinksList list={mainFooterForParents} />
        </MainFooterCard>
        <MainFooterCard
          title={t('footer.forChildren.title')}
          className="col-xl-2 col-lg-2 col-sm-6"
        >
          <MainFooterLinksList list={mainFooterForChildren} />
        </MainFooterCard>
        <MainFooterCard title={t('footer.newsletter.title')} className="col-xl-3 col-lg-3 col-sm-6">
          <MainFooterNewsletter />
        </MainFooterCard>
      </div>
      <div className={styles.copyrightAndDeveloperInfo}>
        <div className={styles.copyrightInfoContent}>
          <p className={styles.copyrightInfo}>{t('footer.copyright')}</p>
          <p className={styles.copyrightInfo}>{t('footer.brandMark')}</p>
        </div>
        <p className={styles.developerInfo}>
          {t('footer.providedBy')}{' '}
          <a href="https://indago-dev.com/" rel="noopener noreferrer" target="_blank">
            IndagoDEV
          </a>
        </p>
      </div>
    </div>
  );
};
