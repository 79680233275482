export enum PAGES_ENUM {
  HOME = '/',
  ABOUT = '/about',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  ONLINE_GAMES = '/online-games',
  ONLINE_GAME_DETAILS = '/online-games/:id',
  OUR_STAFF = '/our-staff',
  ONLINE_LESSONS = '/kidsonlinelessons',
  PRODUCT = '/workbooks/:id',
  PRODUCTS = '/workbooks',
  ONLINE_LESSON_DETAILS = '/kidsonlinelessons/:id',
  MY_PRODUCTS = '/my-workbooks',
  COURSES = '/courses',
  MY_COURSES = '/my-courses',
  FAQ = '/faq',
  CART = '/cart',
  COURSE = '/course/:id',
  LESSON = '/course/:courseId/lesson/:id',
  COLLECTIONS = '/collections',
  COLLECTION = '/collections/:id',
  COLLECTION_PRODUCTS = '/collections/:id/workbooks',
  MY_PROFILE = '/my-profile',
  COLLECTION_COURSES = '/collections/:id/courses',
  FAVORITES = '/favorites',
  FAVORITES_PRODUCTS = '/favorites/workbooks',
  FAVORITES_COURSES = '/favorites/courses',
  ORDERS_HISTORY = '/orders-history',
  ORDER = '/orders-history/:id',
  STAFF_DETAILS = '/staff-details',
  STAFF_DETAILS_PARAMETRIZED = '/staff-details/:id',
  PRIVACY_POLICY = '/privacy-policy',
  COPYRIGHT = '/сopyright',
  CONTACT_US = '/contact-us',
  TERMS_OF_SERVICE = '/terms-of-service',
  INFO = '/info/:slug',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  THANK_YOU = '/thank-you',
  DELETE_ACCOUNT = '/destroy-account',
  NOT_FOUND_404 = '*',
}
