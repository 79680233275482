// @ts-nocheck

import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RangeSlider from 'react-slider';

import styles from './PriceRange.module.scss';

const MIN = 0;
const MAX = 2000;

interface IProps {
  min?: number;
  max?: number;
  currMinPrice?: number | string;
  currMaxPrice?: number | string;
  onChangePrice?: (prices: string[], is_free: string) => void;
  className?: string;
}

export const PriceRange: React.FC<IProps> = ({
  min = MIN,
  max = MAX,
  onChangePrice,
  currMinPrice,
  currMaxPrice,
  className,
}) => {
  const [minPrice, setMinPrice] = useState<number>(min);
  const [maxPrice, setMaxPrice] = useState<number>(max);
  const [isPriceInputChecked, setIsPriceInputChecked] = useState(true);
  const [isFreeInputChecked, setIsFreeInputChecked] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (currMinPrice !== 'NaN' && currMinPrice) setMinPrice(Number(currMinPrice));
    if (currMaxPrice !== 'NaN' && currMaxPrice) setMaxPrice(Number(currMaxPrice));
  }, [currMinPrice, currMaxPrice]);

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);

    if (typeof value === 'number' && !isNaN(value)) {
      if (value > maxPrice) {
        return;
      }

      setMinPrice(value);
    }
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);

    if (typeof value === 'number' && !isNaN(value)) {
      if (value > max) {
        return;
      }

      setMaxPrice(value);
    }
  };

  const handleSliderChange = (values: [number, number]) => {
    const [newMin, newMax] = values;

    if (newMin >= min && newMax <= max) {
      setMinPrice(newMin);
      setMaxPrice(newMax);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (typeof onChangePrice === 'function') {
      if (isPriceInputChecked && isFreeInputChecked) {
        if ((minPrice && maxPrice) || (minPrice === 0 && maxPrice)) {
          onChangePrice([String(minPrice), String(maxPrice)], String(isFreeInputChecked));
        } else {
          onChangePrice(null, String(isFreeInputChecked));
        }
      } else if (isPriceInputChecked && !isFreeInputChecked) {
        onChangePrice([String(minPrice), String(maxPrice)], null);
      } else if (isFreeInputChecked && !isPriceInputChecked) {
        onChangePrice(null, String(isFreeInputChecked));
      }
    }
  };

  return (
    <div className={cn(styles.rangeContainer, className)}>
      <div className={styles.rangeHeader}>
        <h4 className={styles.rangePriceTitle}>{t('priceRange.title')}</h4>
      </div>
      <div className={styles.rangeContent}>
        <form className={styles.rangeForm} onSubmit={handleSubmit}>
          <div className={styles.rangeFilters}>
            <label className={styles.rangeInputLabel}>
              <input
                className={styles.rangeCheckbox}
                type="checkbox"
                name="free"
                onChange={e => setIsFreeInputChecked(e.target.checked)}
                checked={isFreeInputChecked}
              />
              <span></span>
              {t('priceRange.checkBox1')}
            </label>
            <label className={styles.rangeInputLabel}>
              <input
                className={styles.rangeCheckbox}
                type="checkbox"
                name="paid"
                onChange={e => setIsPriceInputChecked(e.target.checked)}
                checked={isPriceInputChecked}
              />
              <span></span>
              {t('priceRange.checkBox2')}
            </label>
          </div>
          <div className={styles.rangeInputsContainer}>
            <input
              className={cn(styles.rangeInput, {
                [styles.rangeInputDisabled]: !isPriceInputChecked,
              })}
              name="min"
              value={minPrice}
              onChange={handleMinPriceChange}
              disabled={!isPriceInputChecked}
              aria-label="min"
            />
            <input
              className={cn(styles.rangeInput, {
                [styles.rangeInputDisabled]: !isPriceInputChecked,
              })}
              name="max"
              value={maxPrice}
              onChange={handleMaxPriceChange}
              onBlur={() => {
                if (maxPrice < minPrice) {
                  setMaxPrice(minPrice + 1);
                }
              }}
              disabled={!isPriceInputChecked}
              aria-label="max"
            />
          </div>
          <RangeSlider
            value={[minPrice, maxPrice]}
            min={min}
            max={max}
            onChange={handleSliderChange}
            className={cn(styles.slider, { [styles.sliderDisabled]: !isPriceInputChecked })}
            thumbClassName={styles.thumbClassName}
            trackClassName={styles.trackClassName}
            minDistance={5}
            disabled={!isPriceInputChecked}
            pearling
          />
          <button className={styles.rangeFormBtn}>{t('priceRange.btnText')}</button>
        </form>
      </div>
    </div>
  );
};
